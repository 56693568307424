.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #70798a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Menüyü sabitliyoruz */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #f8f9fa;
  padding-top: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 120%;
  height: auto;
  transform: scale(0.5);
  transform-origin: top left;
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  font-size: 14px;
  transition: opacity 0.5s ease-in-out; /* Geçiş ekleme */
}

.toast-exit {
  opacity: 0; /* Geçiş sırasında opaklığı sıfırla */
}


.table-container {
  padding: 20px;
  background-color: #15191d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.modern-table th {
  background-color: #343a40;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modern-table td, .modern-table th {
  padding: 15px;
  vertical-align: middle;
}

.modern-table tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.modern-table tbody tr:hover {
  background-color: #e9ecef;
}

.modern-table th, .modern-table td {
  border: none;
}
