/* Help.css */
.container {
    max-width: 1200px;
    margin: auto;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  h4 {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.8;
  }
  
  .faq p {
    margin-left: 20px;
  }
  
  section {
    margin-bottom: 3rem;
  }
  
  .btn {
    padding: 10px 20px;
  }
  