/* Sidebar.css */
.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    background-color: #343a40; /* Koyu gri arka plan */
    padding: 10px;
    transition: width 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .sidebar.expanded {
    width: 250px;
  }
  
  .sidebar.collapsed {
    width: 60px;
  }
  
  .toggle-btn {
    background-color: #007bff; /* Buton için modern bir renk */
    color: white;
    border: none;
  }
  
  .toggle-btn:hover {
    background-color: #0056b3;
  }
  
  .list-group-item {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 12px 20px;
  }
  
  .list-group-item:hover {
    background-color: #495057;
  }
  
  .me-2 {
    margin-right: 8px;
  }
  