.modern-table th {
    background-color: #343a40;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .modern-table td, .modern-table th {
    padding: 15px;
    vertical-align: middle;
  }
  
  .modern-table tbody tr:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
  
  .modern-table tbody tr:hover {
    background-color: #e9ecef;
  }


.modern-table th,
.modern-table td {
  padding: 8px;
  text-align: center;
  word-wrap: break-word; /* İçeriğin taşmasını engeller */
  text-overflow: ellipsis; /* İçerik sığmazsa üç nokta ile keser */
  white-space: normal; /* Satır içinde taşan kelimeleri kırarak birden fazla satıra yayar */
}
  
@media only screen and (max-width: 600px) {
    .modern-table {
      font-size: 12px; /* Mobil cihazlarda daha küçük font */
    }
    .modern-table th,
    .modern-table td {
      padding: 4px; /* Mobil cihazlarda daha küçük boşluklar */
    }
  }
  