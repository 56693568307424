/* Home.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .header {
    background-color:#282c34;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    align-items: center;
  }
  h1, p {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .logo img {
    max-width: 120px;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .nav ul li {
    margin: 0 1rem;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .btn-signin {
    background-color: #00aaff;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 5px;
  }
  
  .hero {
    background-color: #f0f8ff;
    padding: 5rem 2rem;
    text-align: center;
    position: relative;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .bubbles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 2rem;
  }
  
  .bubble {
    background-color: #00aaff;
    color: white;
    padding: 2rem;
    border-radius: 50%;
    text-align: center;
  }
  
  .api {
    background-color: #ffaa00;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    padding: 4rem 2rem;
    background-color: #f8f9fa;
  }
  
  .feature {
    max-width: 300px;
    text-align: center;
  }
  
  .btn-start, .btn-learn, .btn-documentation {
    background-color: black;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    margin-top: 1rem;
  }
  