.modern-table th {
  background-color: #343a40;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modern-table td, .modern-table th {
  padding: 15px;
  vertical-align: middle;
}

.modern-table tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.modern-table tbody tr:hover {
  background-color: #e9ecef;
}

.modern-table th, .modern-table td {
  border: none;
}
